@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Work Sans'), local('WorkSans-Regular'),
       url('./fonts/work-sans-v3-latin-regular.woff2') format('woff2'),
       url('./fonts/work-sans-v3-latin-regular.woff') format('woff');
}

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Work Sans SemiBold'), local('WorkSans-SemiBold'),
       url('./fonts/work-sans-v3-latin-600.woff2') format('woff2'),
       url('./fonts/work-sans-v3-latin-600.woff') format('woff');
}

@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Mono Bold'), local('RobotoMono-Bold'),
       url('./fonts/roboto-mono-v5-latin-700.woff2') format('woff2'),
       url('./fonts/roboto-mono-v5-latin-700.woff') format('woff');
}

* {
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
}